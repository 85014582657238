
import userModel , {userPlaceholder} from "@/models/user"
import Vue from "vue";
import {UserById} from "@/repositories/user"
import router from '@/router'
export default Vue.extend({
  name: "users-list",
  components:{
    
  },
  data() {
      return {
          loading : true,
          user : userPlaceholder,
          viewable : [
              'Id',
              'Name',
              'Name_ar',
              'Email',
              'Img',
              'Serial',
              'Points',
              'Phone',
              'Breif',
              'Website',
              'Instagram',
              'Twitter',
              'Role',
          ]
      }
  },   
  methods:{
      edit(){
          
          const currentPath  = router.currentRoute.path
          const path = currentPath.replace("view", "edit");
          router.push(path)
      }
  } ,
  created()  {
      UserById(parseInt(router.currentRoute.params.id)).then((res:userModel) => {
          this.user = res
          this.loading = false
      })
  }
});
